import React, { useCallback, useEffect, useState } from "react"
// 
import { useNavigate, useParams } from "react-router-dom"
// 
import useAppContext from "../../context/hook"
import StateStatus from "../../utils/status"
// 
import Modal from "../../components/Modal"
import Input from "../../components/Input"
import Button from "../../components/Button"
import Loading from "../../components/Loading"
import feedback from "../../components/Feedback"
// 
import TopPost from "./components/TopPost"
import ListOfMetrics from "./components/ListOfMetrics"
import InfluencerCardData from "./components/InfluencerCardData"
import ListOfCategoryMetrics from "./components/ListOfCategoryMetrics"
// 
import { buildValue } from "../../utils/formatters"
// 
import "./index.scss"

const Comparator = () => {
    const { id } = useParams()
    // 
    const { 
        state,
        getProfilesToCompare, 
        updateStatus,
        sendFormEmail
    } = useAppContext()
    // 
    const {
        profiles,
        getProfilesToCompareStatus,
        sendFormEmailStatus,
    } = state
    // 
    const navigate = useNavigate()
    // 
    const [showEmailModal, setEmailModal] = useState(true)
    const [errorMessage, updateErrorMessage] = useState()

    const onFinish = (e) => {
        e.preventDefault()
        e.stopPropagation()
        
        const email = e.target[0] && e.target[0].value
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
        // 
        const match = email && email.match(emailRegex)

        if(!email || !match) {
            updateErrorMessage("Use um e-mail válido")
        } else {
            updateStatus({
                name: "sendFormEmailStatus",
                status: StateStatus.loading
            })

            sendFormEmail({ id, email })
        }
    }

    const redirectToFormCompare = useCallback(() => {
        const fbToken = window.localStorage.getItem("fb_token")
            
        if(fbToken) {
            navigate(`/?fb_token=${fbToken}`)
        } else {
            navigate("/connect")
        }
    }, [navigate])

    useEffect(() => {
        if(!id) {
            navigate("/error?type=3")
        } else {
            getProfilesToCompare({ id: id })
        }
    }, [id, navigate, getProfilesToCompare])
    
    // STATUS user auth
    useEffect(() => {
        if (getProfilesToCompareStatus === StateStatus.retain) {
            updateStatus({
                name: "getProfilesToCompareStatus",
                status: StateStatus.loading
            })
            
            setTimeout(() => {
                getProfilesToCompare({ id: id })
            }, 5000);
        } 
    }, [getProfilesToCompareStatus, id, getProfilesToCompare, updateStatus, navigate])

    // STATUS send form email
    useEffect(() => {
        if (sendFormEmailStatus === StateStatus.succeeded) {
            feedback.success("E-mail salvo com sucesso")

            redirectToFormCompare()
        } else if (sendFormEmailStatus === StateStatus.failed) {
            feedback.error("Erro ao enviar e-mail")
        }
    }, [sendFormEmailStatus, navigate, redirectToFormCompare])

    return <div className="page comparator-page">
        {(getProfilesToCompareStatus === StateStatus.loading || getProfilesToCompareStatus === StateStatus.idle || getProfilesToCompareStatus === StateStatus.retain) &&
            <Loading />
        }

        {getProfilesToCompareStatus === StateStatus.failed &&
            <Modal
                className="email-modal"
                title={<h3 style={{ marginTop: '0px' }}>Ops! Precisaremos metrificar os perfis</h3>}
                showModal={showEmailModal}
                onClose={() => {
                    setEmailModal(false)
                    
                    navigate("/")
                }}
            >
                <form
                    onSubmit={onFinish}
                >
                    <br/>
                    <Input 
                        type="email"
                        msgError={errorMessage}
                        className="profile-url"
                        onChange={() => updateErrorMessage()}
                        label="Informe o e-mail que receberá o link da comparação"
                    />

                    <Button 
                        type="submit"
                        className="btn-submit"
                        isLoading={sendFormEmailStatus === StateStatus.loading}
                    >
                        Enviar
                    </Button>
                </form>

                <Button
                    onClick={() => redirectToFormCompare()}
                    className="outline-primary"
                >
                    <i className="icon icon-share-network" />
                    Comparar outro perfil
                </Button>

            </Modal>
        }  

        {getProfilesToCompareStatus === StateStatus.succeeded &&
            <>
                <h1>Compare seu desempenho</h1>
                <h4>Análise alguns dos principais dados utilizados por grandes agências na hora de decidir os melhores perfis para sua campanha.</h4>

                <Button
                    onClick={() => navigate("/")}
                >
                    <i className="icon icon-share-network" />
                    Comparar outro perfil
                </Button>

                {/* PREVIEWS */}
                <div className="previews">
                    {profiles && profiles.map((profile) => {
                        return <InfluencerCardData 
                            influencer={profile}
                            key={profile.username}
                            showNetwork={true}
                        >
                            <span className="username">{profile.username}</span>
                            
                            <span className="followers">{buildValue("number", profile.followers)} seguidores</span>

                            <span className="classification">{profile.classification}</span>

                            <p className="bio">
                                <b>Bio</b>

                                <span>{profile.bio}</span>
                            </p>
                        </InfluencerCardData>
                    })}
                </div>

                {/* ENGAGEMENTS */}
                <div className="engagements" key="box-engagements">
                    <h2>Engajamento</h2>
                    <h3>A métrica preferida das agências!</h3>

                    {profiles && profiles.length > 0 && 
                        <ListOfMetrics 
                            key="engagements"
                            influencers={[...profiles]}
                            valueName="engagementRate"
                            suffixValue="%"
                        />
                    }
                </div>
                
                {/* AIR SCORES */}
                <div className="airscores" key="box-airscores">
                    <h2>Airscore</h2>
                    <h3>Ainda mais profunda o Airscore supera a taxa de engajamento, garantido análise mais precisa. o Cálculo avalia números de seguidores, interações e conteúdo, focando em autenticidade e relevância</h3>

                    {profiles && profiles.length > 0 && 
                        <ListOfMetrics 
                            key="airscores"
                            influencers={[...profiles]}
                            valueName="airscore"
                            suffixValue=" / 1000"
                        />
                    }
                </div>

                {/* TOP POSTS */}
                <div className="top-posts">
                    <h2>Melhor post</h2>

                    <div className="posts">
                        {profiles && profiles.length > 0 && 
                            profiles.map((profile, index) => {
                                return <InfluencerCardData key={`top-posts-${index}`} influencer={profile}>
                                    <TopPost 
                                        post={profile.topPost}
                                    />
                                    <div></div>
                                </InfluencerCardData>
                            })
                        }
                    </div>
                </div>

                {/* ENGAGEMENT BY SUBJECT */}
                <div className="engagements-by-subject">
                    <h2>Airscore por assunto</h2>
                    <h3>Métrica exclusiva Airfluencers que pontua de 0 a 1 mil a qualidade do influenciador em cada assunto.</h3>

                    <div className="subjects">
                        {profiles && profiles.length > 0 && 
                            profiles.map((profile, index) => {
                                return <InfluencerCardData key={`engagements-by-subject-${index}`} influencer={profile}>
                                    {profile.bestCategories && profile.bestCategories.length > 0
                                        ?
                                        <ListOfCategoryMetrics 
                                            categories={profile.bestCategories}
                                            worstCategories={profile.worstCategories}
                                        />
                                        :
                                        <div className="no-data">
                                            <p>Não há categoria</p>

                                            <div>Este perfil não possui nenhuma categoria</div>
                                        </div>
                                    }
                                </InfluencerCardData>
                            })
                        }
                    </div>
                </div>
                
                {/* TOP HASHTAGS */}
                <div className="top-hashtags">
                    <h2>Top 5 hashtags mencionadas</h2>

                    <div className="hashtags">
                        {profiles && profiles.length > 0 && 
                            profiles.map((profile, index) => {
                                return <InfluencerCardData key={`hashtags-${index}`} influencer={profile}>
                                    {profile.hashtags && profile.hashtags.length > 0
                                    ?
                                    <ListOfCategoryMetrics 
                                        categories={profile.hashtags}
                                    />
                                    :
                                    <div className="no-data">
                                        <p>Não há hashtags mencionadas</p>

                                        <div>Este perfil não mencionou nenhuma hashtag em suas publicações</div>
                                    </div>
                                }
                                </InfluencerCardData>
                            })
                        }
                    </div>
                </div>

                {/*  ABOUT THE AUDIENCE */}
                <div className="about-the-audience">
                    <h2>Sobre a audiência</h2>

                    <div className="about">
                        {profiles && profiles.length > 0 && 
                            profiles.map((profile, index) => {
                                return <InfluencerCardData key={`hashtags-${index}`} influencer={profile}>
                                    {profile.audienceGender && profile.audienceGender.label && profile.audienceAge && profile.audienceAge.label ? <>
                                        <div className="audience-data">
                                            Sexo

                                            <span><b>{profile.audienceGender.label}</b> ({profile.audienceGender.value}%)</span>
                                        </div>

                                        <div className="audience-data">
                                            Idade

                                            <span><b>{profile.audienceAge.label}</b> ({profile.audienceAge.value}%)</span>
                                        </div>
                                    </>
                                    :
                                    <div className="no-data">
                                        <p>Dados de audiência indisponíveis:</p>
                                        
                                        <div>Este dado só é apresentado para <b>perfis comerciais</b> e com pelo menos <b>10 mil seguidores.</b></div>
                                    </div>
                                    }
                                    
                                </InfluencerCardData>
                            })
                        }
                    </div>
                </div>
            </>
        }
    </div>
}

export default Comparator

