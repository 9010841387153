import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
// 
import Avatar from "../../../../components/Avatar"
// 
import "./index.scss"

const ListOfMetrics = ({
    influencers,
    valueName,
    suffixValue
}) => {
    const [sortedProfiles, updateSortedProfiles] = useState([])
    
    useEffect(() => {
        const order = (list) => {
            list.sort((a, b) => b[valueName] - a[valueName])
            
            return list;
        }
        
        updateSortedProfiles(order([...influencers]))
    }, [influencers, valueName])

    return <div className="list-of-metrics">
        {sortedProfiles.map((influencer, index) => {
            return <div className={`list-item ${index === 0 ? "first" : ""}`} key={`${valueName}-${index}`}>
                <div className="influencer">
                    <span className="number">#{index + 1}</span>

                    <Avatar 
                        src={influencer.avatar}
                    />

                    <span className="name">{influencer.name}</span>
                </div>

                <span className="value">{influencer[valueName]}{suffixValue}</span>
            </div>
        })}
    </div>
}

ListOfMetrics.defaultProps = {
    influencers: [],
}

ListOfMetrics.propTypes = {
    influencers: PropTypes.array,
    valueName: PropTypes.string,
    suffixValue: PropTypes.string,
}

export default ListOfMetrics