class PostModel {
    caption
    engagementRate = 0
    permalink
    thumbnail
    
    fromJson(data) {
        this.thumbnail = data["thumbnail"]

        this.description = data["caption"]
        this.descriptionSlice = data["caption"] ? `${data["caption"].slice(0, (data["caption"].length > 120 ? 120 : data["caption"].length))}${data["caption"].length > 120 ? "..." : ""}` : ""
        
        this.engagementRate = ((data["engagement_rate"] || 0) * 100).toFixed(2)

        return this
    }
}

export default PostModel