import React from 'react'
import Slider from 'react-slick'
//
import amazonKindle from "../../../../assets/images/clients/amazon-kindle.png"
import azul from "../../../../assets/images/clients/azul.png"
import globo from "../../../../assets/images/clients/globo.png"
import loral from "../../../../assets/images/clients/loral.png"
import nestle from "../../../../assets/images/clients/nestle.png"
import nubank from "../../../../assets/images/clients/nubank.png"
import sallva from "../../../../assets/images/clients/sallva.png"
import telecine from "../../../../assets/images/clients/telecine.png"
import telefone from "../../../../assets/images/clients/telefone.png"
import unilever from "../../../../assets/images/clients/unilever.png"
//
import './index.scss'

const Clients = () => {
  const images = [
    amazonKindle,
    azul,
    globo,
    loral,
    nestle,
    nubank,
    sallva,
    telecine,
    telefone,
    unilever,
  ]

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 12,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 1224,
        settings: {
          slidesToShow: 10,
          slidesToScroll: 2,
        },
      },
    ],
  }

  return (
    <div className="clients">
      <Slider {...settings}>
        {images.map((img) => (
          <img
            key={img.toString()}
            src={img}
            alt={`logo da marca: ${img.toString()}`}
          />
        ))}
      </Slider>
    </div>
  )
}

export default Clients
