import React, { useState } from "react"
//
import TutorialConnectIG from "../TutorialConnectIG"
//
import Modal from "../../../../components/Modal"
//
import instagramImg from "../../../../assets/icons/instagram.svg"
//
import "./index.scss"

const ConnectBox = () => {
  const [showTutorial, setShowTutorial] = useState(false)
  const [showConnectModal, setConnectModal] = useState(false)
  // 
  const [statusConnect, setStatusConnect] = useState("")
  
  return (
    <>
      <TutorialConnectIG
        show={showTutorial}
        callback={() => {
          setShowTutorial(false)
        }}
        updateStatusConnect={(status) => {
          setStatusConnect(status)

          setConnectModal(true)
        }}
        hiddenTutorial={() => {
          setShowTutorial(false)
        }}
      />

      <Modal
        title={<h3 style={{ marginTop: '0px' }}>Conecte o Instagram pelo Facebook</h3>}
        showModal={showConnectModal}
        onClose={() => setConnectModal(false)}
      >
        <div className="modal-message">
          {statusConnect}
        </div>
      </Modal>

        <div
          className="btn-social fb hover"
          role="presentation"
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()

            setShowTutorial(true)
          }}
        >
          <div className="icon-social">
            <img src={instagramImg} alt="logo do instagram" />
          </div>
            
          <span className="text-button">Conecte o Instagram pelo Facebook</span>
        </div>
    </>
  )
}

export default ConnectBox
