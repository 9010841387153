import PostModel from "./PostModel"

class InfluencerModel {
    bio
    bioSlice
    name
    avatar
    followers
    profileUrl
    username
    airscore
    engagementRate
    // 
    topPost = new PostModel()
    // 
    hashtags = []
    // 
    bestCategories = []
    worstCategories = []
    // 
    audienceAge
    audienceGender

    fromJson(data) {
        this.airscore = Math.round((data["airscore"] || 0) * 1000)
        this.followers = data["followers"]
        this.name = data["name"]
        this.bio = data["biography"] 
        this.network = data["network"]
        this.username = data["username"]
        this.avatar = data["profile_picture"]
        this.profileUrl = data["permalink"] || data["url"]
        this.engagementRate = (data["engagement_rate"] || 0) / 100
        // 
        const classification = this._getClassification(this.followers)
        
        this.classification = classification

        this.topPost = new PostModel().fromJson(data["highest_post"])
        
        this.hashtags = (data["hashtags"] || []).slice(0, 5)

        if(data["categories"]) {
            const allCategories = data["categories"].sort((a, b) => b.value - a.value).map((c, i) => {
                return {
                    ...c,
                    index: i,
                    value: Math.round((c["value"] || 0) * 1000),
                }
            })

            if(allCategories) {
                this.bestCategories = (allCategories || []).slice(0, 3)            
            
                const worts = allCategories.reverse().slice(0, 3)
                
                this.worstCategories = worts.reverse()
            }
        }

        if(data["audience_gender"]) {
            this.audienceGender = {
                ...data["audience_gender"],
                value: (data["audience_gender"]["value"] || 0) / 100 
            }
        }

        if(data["audience_age"]) {
            this.audienceAge = {
                ...data["audience_age"],
                value: (data["audience_age"]["value"] || 0) / 100 
            }
        }
        
        return this
    }

    _getClassification(followers) {
        if (followers) {
            if (followers >= 1000000) return "mega"
            if (followers >= 500000 && followers < 1000000) return "macro"
            if (followers >= 50000 && followers < 500000) return "inter"
            if (followers >= 10000 && followers < 50000) return "micro"
            
            return "nano"
        }
    }
}

export default InfluencerModel