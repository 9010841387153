import React from 'react'
import propTypes from 'prop-types'
import './styles.scss'

const Modal = ({ showModal, title, children, onClose, className }) =>
  showModal ? (
    <div className={`modal ${className}`}>
      <div className="modal-content">
        <div className="modal-header">
          <div>{title}</div>
          <button type="button" onClick={onClose} className="close">
            &times;
          </button>
        </div>
        
        {children}
      </div>
    </div>
  ) : null

  Modal.defaultProps = {
  showModal: true,
  className: "",
  title: null,
  children: [],
  onClose: () => {},
}

Modal.propTypes = {
  showModal: propTypes.bool,
  className: propTypes.string,
  title: propTypes.any,
  children: propTypes.any,
  onClose: propTypes.func,
}


export default Modal
