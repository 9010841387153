import React from "react"
import { Routes as RRoutes, Route } from "react-router-dom"
// 
import Home from "./home"
import Error from "./error"
import Connect from "./connect"
import Comparator from "./comparator"

const Routes = () => {
    return <RRoutes>
        <Route exact path="/connect" element={<Connect />} />
        
        <Route path="/" element={<Home />} />
        <Route path="/error" element={<Error />} />
        <Route path="/comparator/:id" element={<Comparator />} />
    </RRoutes>
}


export default Routes