import React from "react"
import PropTypes from "prop-types"
// 
import "./index.scss"

const Button = ({
    onClick,
    children,
    isLoading,
    type,
    className,
}) => {
    return <button
        type={type}
        className={`button ${className} ${isLoading ? "is-loading" : ""}`}
        onClick={() => {
            if (!isLoading && onClick) onClick()
        }}
        disabled={isLoading}
    >   
        {isLoading ?
            <span className="loader" />
            :
            children
        }
    </button>
}

Button.defaultProps = {
    className: "",
    type: "button",
}

Button.propTypes = {
    className: PropTypes.string,
    type: PropTypes.string,
}

export default Button
