import React from "react"
import PropTypes from "prop-types"
// 
import instagramImg from "../../assets/icons/instagram-circle.svg"
import facebookImg from "../../assets/icons/facebook–circle.svg"
import tiktokImg from "../../assets/icons/tiktok-circle.svg"
import twitterImg from "../../assets/icons/x-twitter-circle.svg"
import youtubeImg from "../../assets/icons/youtube-circle.svg"
import twitchImg from "../../assets/icons/twitch-circle.svg"
// 
import "./index.scss"

const Avatar = ({
    src,
    size,
    networkSize,
    network,
    onClick,
    isSmall
}) => {
    return <div className={`avatar-network ${isSmall ? "is-small" : ""}`}>
        <img 
            size={size} 
            src={src} 
            onClick={onClick} 
            className="avatar"
            alt="foto de perfil do influenciador"
        />

        {network === "instagram" && <img style={{ widht: networkSize }} className="network-logo instagram-logo" src={instagramImg} alt="logo da rede social instagram" />}

        {network === "facebook" && <img style={{ widht: networkSize }} className="network-logo facebook-logo" src={facebookImg} alt="logo da rede social facebook" />}

        {network === "tiktok" && <img style={{ widht: networkSize }} className="network-logo tiktok-logo" src={tiktokImg} alt="logo da rede social tiktok" />}

        {network === "twitter" && <img style={{ widht: networkSize }} className="network-logo twitter-logo" src={twitterImg} alt="logo da rede social twitter" />}

        {network === "twitch" && <img style={{ widht: networkSize }} className="network-logo twitch-logo" src={twitchImg} alt="logo da rede social twitch" />}
        
        {network === "youtube" && <img style={{ widht: networkSize }} className="network-logo youtube-logo" src={youtubeImg} alt="logo da rede social youtube" />}
    </div>
}

Avatar.defaultProps = {
    src: "",
    size: 227,
    network: "",
    isSmall: false,
    networkSize: 88,
}

Avatar.propTypes = {
    src: PropTypes.string,
    network: PropTypes.string,
    onClick: PropTypes.func,
    isSmall: PropTypes.bool,
    size: PropTypes.any,
    networkSize: PropTypes.any,
}

export default Avatar