import { createContext } from "react"
// 
import StateStatus from "../utils/status"

export const INITIAL_STATE = {
    // status
    userAuthStatus: StateStatus.idle,
    sendFormStatus: StateStatus.idle,
    sendFormEmailStatus: StateStatus.idle,
    getProfilesToCompareStatus: StateStatus.idle,
    // 
    profiles: []
}

export const AppContext = createContext({
    state: INITIAL_STATE,
    // aux
    updateStatus: () => {},
    // requests
    userAuth: () => {},
    sendForm: () => {},
    sendFormEmail: () => {},
    getProfilesToCompare: () => {},
})
