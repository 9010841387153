import React, { useCallback, useEffect, useRef, useState } from "react"
// 
import { useNavigate } from "react-router-dom"
// 
import useAppContext from "../../context/hook"
// 
import Button from "../../components/Button"
import Input from "../../components/Input"
import Loading from "../../components/Loading"
// 
import cards from "../../assets/images/comparator.png"
// 
import StateStatus from "../../utils/status"
import "./index.scss"

const Home = () => {
    const query = new URLSearchParams(window.location.href.split("?")[1])
    const token = query.get("fb_token")
    // 
    const navigate = useNavigate()
    // 
    const { 
        state, 
        updateStatus, 
        sendForm,
        userAuth
    } = useAppContext()
    // 
    const { 
        userAuthStatus,
        sendFormStatus 
    } = state
    // 
    const form = useRef(null)
    // 
    const [errorMessage, updateErrorMessage] = useState()

    const postForm = useCallback((url) => {
        const regexIg = /(?:https?:\/\/)?(?:www\.)?instagram\.com\/([a-zA-Z0-9_.]{1,30})\/?/
        const match = url && url.match(regexIg)

        if(!url || !match) {
            updateErrorMessage("Use um e-mail válido")
        } else {
            updateStatus({
                name: "sendFormStatus",
                status: StateStatus.loading
            })

            sendForm({ url })
        }
    }, [sendForm, updateStatus])

    const onFinish = (e) => {
        e.preventDefault()
        e.stopPropagation()
        
        const url = e.target[0] && e.target[0].value
        
        postForm(url)
    }

    // CLEAR status
    useEffect(() => {
        updateStatus({ name: "userAuthStatus", status: StateStatus.idle })
        updateStatus({ name: "sendFormEmailStatus", status: StateStatus.idle })
        updateStatus({ name: "getProfilesToCompareStatus", status: StateStatus.idle })
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // CHECK fb token
    useEffect(() => {
        const accessToken = window.localStorage.getItem("access_token")

        if(!accessToken) {
            if(!token) {
                navigate("/connect")
            } else {
                updateStatus({
                    name: "userAuthStatus",
                    status: StateStatus.loading
                })
    
                userAuth({ accessToken: token })
            }
        } else {
            updateStatus({
                name: "userAuthStatus",
                status: StateStatus.succeeded
            })
        }

        let currURL = window.location.href
        let afterDomain = currURL.substring(currURL.lastIndexOf("/#/") + 1)
        let beforeQueryString = afterDomain.split("?")[0]

        window.history.pushState("", "Home", "" + beforeQueryString)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // STATUS user auth
    useEffect(() => {
        if (userAuthStatus === StateStatus.failed) {
            navigate("/error/?type=3")
            
            window.localStorage.clear()
        }
    }, [userAuthStatus, navigate])
  
    // STATUS send form
    useEffect(() => {
        if(sendFormStatus === StateStatus.succeeded) {
            updateStatus({
                name: "sendFormStatus",
                status: StateStatus.idle
            })
        } else if (sendFormStatus === StateStatus.failed) {
            navigate("error/?type=3")
            
            window.localStorage.clear()
        }
    }, [sendFormStatus, navigate, updateStatus, postForm])

    return <div className="page home-page">
        {(userAuthStatus === StateStatus.idle || userAuthStatus === StateStatus.loading) && 
            <Loading />
        }

        {userAuthStatus === StateStatus.failed && 
            <p>Erro</p>
        }

        {userAuthStatus === StateStatus.succeeded && <>
            <h1>Compare seus dados</h1>
            
            <h4>Cole o link do perfil que deseja comparar.</h4>

            <img src={cards} alt="Dois cartões na cor rosa com 4 linhas em rosa escuro cada, sendo que em duas dessas linhas há uma coroa dourada" />

            <form
                ref={form}
                onSubmit={onFinish}
            >
                <Input 
                    msgError={errorMessage}
                    className="profile-url"
                    onChange={() => updateErrorMessage()}
                    label="Link do perfil que desejar comparar:"
                />

                <Button 
                    type="submit"
                    className="btn-submit"
                    isLoading={sendFormStatus === StateStatus.loading}
                >
                    Comparar
                </Button>
            </form>
        </>}
    </div>
}

export default Home