import React from "react"
import PropTypes from "prop-types"
// 
import "./index.scss"

const Card = ({
    children,
    className
}) => {
    return <div className={`card ${className}`}>
        {children}
    </div>
}

Card.defaultProps = {
    className: "",
}

Card.propTypes = {
    children: PropTypes.any,
    className: PropTypes.string,
}

export default Card
