import { useContext } from "react"
// 
import { AppContext } from "."

const useAppContext = () => {
    const context = useContext(AppContext)    

    return context
}

export default useAppContext