import axios from "axios"

const apiUrl = process.env.REACT_APP_BASEURL

export const withoutInterceptorsApi = axios.create({
    baseURL: apiUrl,
    headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
    },
})
