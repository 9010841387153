import React, { useCallback, useReducer } from "react"
import PropTypes from "prop-types"
// 
import { useNavigate } from "react-router-dom"
// 
import { AppContext, INITIAL_STATE } from "."
// 
import { compareProfile, compareProfiles, sendAccessToken, sendEmail } from "../services/requests"
import StateStatus from "../utils/status"
// 
import appContextReducer from "./reducer"
import InfluencerModel from "../models/InfluencerModel"
// 
import AppContextActions from "../utils/actions"

const AppContextProvider = ({ children }) => {
    const navigate = useNavigate()
    // 
    const [state, dispatch] = useReducer(appContextReducer, INITIAL_STATE)

    // AUX
    const updateStatus = useCallback(({ name, status }) => {
        dispatch({
            type: AppContextActions.UpdateStatus,
            name, 
            status
        })
    }, [dispatch])

    // REQUESTS
    const userAuth = useCallback(({ accessToken }) => {
        window.localStorage.setItem("fb_token", accessToken)

        sendAccessToken({
            "access_token": accessToken
        }).then((response) => {
            if(response.data && response.data["access_token"]) {
                window.localStorage.setItem("access_token", response.data["access_token"])
                
                dispatch({
                    type: AppContextActions.UpdateStatus,
                    name : "userAuthStatus", 
                    status: StateStatus.succeeded
                })
            } else {
                dispatch({
                    type: AppContextActions.UpdateStatus,
                    name : "userAuthStatus", 
                    status: StateStatus.failed
                })
            }
        }).catch(() => {
            dispatch({
                type: AppContextActions.UpdateStatus,
                name : "userAuthStatus", 
                status: StateStatus.failed
            })
        })
    }, [dispatch])

    const sendForm = useCallback(({ url }) => {
        compareProfile({
            "instagram_profile": url
        }).then((response) => {
            if(response.data && response.data.id) {
                navigate(`/comparator/${response.data.id}`)

                dispatch({
                    type: AppContextActions.UpdateStatus,
                    name : "sendFormStatus", 
                    status: StateStatus.succeeded
                })
            } else {
                dispatch({
                    type: AppContextActions.UpdateStatus,
                    name : "sendFormStatus", 
                    status: StateStatus.failed
                })
            }
        }).catch(() => {
            dispatch({
                type: AppContextActions.UpdateStatus,
                name : "sendFormStatus", 
                status: StateStatus.failed
            })
        })
    }, [dispatch, navigate])

    const sendFormEmail = useCallback(({ id, email }) => {
        sendEmail(
            { "email": email },
            id
        ).then(() => {
            dispatch({
                type: AppContextActions.UpdateStatus,
                name : "sendFormEmailStatus", 
                status: StateStatus.succeeded
            })
        }).catch(() => {
            dispatch({
                type: AppContextActions.UpdateStatus,
                name : "sendFormEmailStatus", 
                status: StateStatus.failed
            })
        })
    }, [dispatch])

    const getProfilesToCompare = useCallback(({ id }) => {
        compareProfiles({
            "id": id
        }).then((response) => {
            if(response.data && response.data.status === "finished" &&  response.data.comparatives) {
                let profiles = []

                profiles = response.data.comparatives.map((profile) => {
                    const influencer = new InfluencerModel().fromJson(profile)
    
                    return influencer
                })
    
                dispatch({
                    type: AppContextActions.getProfilesToCompare,
                    profiles: profiles
                })

                dispatch({
                    type: AppContextActions.UpdateStatus,
                    name : "getProfilesToCompareStatus", 
                    status: StateStatus.succeeded
                })
            } else if(response.data && response.data.status === "created") {
                dispatch({
                    type: AppContextActions.UpdateStatus,
                    name : "getProfilesToCompareStatus", 
                    status: StateStatus.retain
                })
            } else if(response.data && response.data.status === "waiting") {
                dispatch({
                    type: AppContextActions.UpdateStatus,
                    name : "getProfilesToCompareStatus", 
                    status: StateStatus.failed
                })
            } else {
                dispatch({
                    type: AppContextActions.UpdateStatus,
                    name : "getProfilesToCompareStatus", 
                    status: StateStatus.failed
                })
            }
        }).catch(() => {
            dispatch({
                type: AppContextActions.UpdateStatus,
                name : "getProfilesToCompareStatus", 
                status: StateStatus.failed
            })
        })
    }, [dispatch])

    return <AppContext.Provider
        value={{ 
            state,
            // aux
            updateStatus,
            // requests
            userAuth,
            sendForm,
            sendFormEmail,
            getProfilesToCompare       
        }}
    >
        { children }
    </AppContext.Provider>
}

AppContextProvider.propTypes = {
    children: PropTypes.node,
}

export default AppContextProvider