import api from "./api";
import { withoutInterceptorsApi } from "./withoutInterceptorsApi";

export const sendAccessToken = (data) => api.post("/connect/", data)
// response: { "access_token": "" }

export const compareProfile = (data) => api.post("/comparative/", data)
// response: { "id": "", "status": "created" }

export const sendEmail = (data, id) => api.post(`/comparative/${id}/email`, data)
// response: { "id": "", "status": "created" }

export const compareProfiles = ({ id }) => {
    return withoutInterceptorsApi.get(`/comparative/${id}`)
}
// response: { "id": "", "status": "created" }


// 
// "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjbGllbnRfaWQiOjN9.EikGW2IV6lkswLgTEKmssUJE2RdpG3wsEkuS9laiLRo"

// eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjbGllbnRfaWQiOjN9.EikGW2IV6lkswLgTEKmssUJE2RdpG3wsEkuS9laiLRo