import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
//
import FacebookLogin from "react-facebook-login"
//
import instagramImg from "../../../../assets/icons/instagram.svg"
//
import './index.scss'

const TutorialConnectIG = ({ show, callback, updateStatusConnect, hiddenTutorial }) => {
  const navigate = useNavigate()
  // 
  const [maxIndex, setMaxIndex] = useState(1)
  const [arrayIndex, setArrayIndex] = useState([])
  const [stepActive, setsSepActive] = useState(1)
  const [completTutorial, setCompletTutorial] = useState(false)
  const [businessAccount, setBusinessAccount] = useState(true)
  // 
  const titleSteps = ['Conta comercial', 'Conta FB', 'Página FB']
  
  const prevent = (e) => {
    e.preventDefault()
    e.stopPropagation()
  }

  const goTo = (index) => {
    if (index !== stepActive) {
      if (index <= maxIndex) {
        setsSepActive(index)
        setCompletTutorial(false)
        setBusinessAccount(true)

        setArrayIndex([...arrayIndex, index])
      }
    }
  }

  const changeStep = (action) => {
    if (stepActive === 1 || stepActive === 2) {
      if (action === 'n') setBusinessAccount(false)
      else setsSepActive(stepActive + 1)
    } else if (stepActive === 3) {
      if (action === 'n') setBusinessAccount(false)
      else {
        setsSepActive(4)
        setCompletTutorial(true)
      }
    }
  }

  const openVideo = () => {
    setBusinessAccount(true)
    window.open('https://www.youtube.com/watch?v=oXLwtmlS7FI', '_blank')
  }

  const facebookCallback = async (response) => {
    if (response.accessToken) {  
      hiddenTutorial()
      
      navigate(`/?fb_token=${response.accessToken}`)
    } else if (response.status !== "unknown") {
      updateStatusConnect("Espere! Estamos validando sua conta...")
    } else {
      updateStatusConnect(
        "Algo deu errado! Feche o pop-up e tente novamente..."
      )
    }
  }

  useEffect(() => {
    setMaxIndex(1)
    setArrayIndex([])
    setsSepActive(1)
    setCompletTutorial(false)
    setBusinessAccount(true)
  }, [show])
  
  useEffect(() => {
    const index = arrayIndex
    const max = maxIndex
    
    setArrayIndex([...index, stepActive])
    
    setMaxIndex(stepActive > max ? stepActive : max)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepActive])

  useEffect(() => {
    window.localStorage.clear("")
  }, [])

  return (
    show && (
      <div
        className="container-tutorial-modal"
        role="presentation"
        onClick={() => callback(false)}
      >
        <div className="tutorial-modal" role="presentation" onClick={prevent}>
          <i
            className="air close icon-close"
            role="presentation"
            onClick={() => callback(false)}
          />

          <div className="header-steps">
            {titleSteps.map((item, i) => (
              <div
                role="presentation"
                key={i}
                onClick={() => goTo(i + 1)}
                className={`header-step ${
                  stepActive === i + 1 ? 'atual' : ''
                } ${completTutorial || stepActive >= i + 1 ? 'complet' : ''} ${
                  i === 0 || arrayIndex.includes(i + 1) ? 'cursor-pointer' : ''
                }`}
              >
                <span className="step-num">{i + 1}</span>
                <span className="step-title">{item}</span>
              </div>
            ))}

            {completTutorial && (
              <p className="title-complet-tutorial">Tudo pronto!</p>
            )}
          </div>

          {!completTutorial ? (
            <div className="body-steps">
              {businessAccount && (
                <span className="step-text">
                  Para conectar seu perfil do Instagram, verifique se a sua
                  conta segue os critérios solicitados.
                </span>
              )}

              {/* step 1 */}
              {stepActive === 1 && (
                <div
                  className={`step-content step-1 ${
                    arrayIndex[arrayIndex.length - 2] > stepActive
                      ? 'reverter'
                      : ''
                  }`}
                >
                  {!businessAccount ? (
                    <>
                      <span className="step-text">
                        Veja o vídeo e siga os passos para poder transformar o
                        seu perfil em uma conta comercial no Instagram.
                      </span>

                      <button
                        type="button"
                        onClick={() => openVideo()}
                        className="button-animation button-play"
                      >
                        <div className="icon-play" />
                        Veja como fazer
                      </button>
                    </>
                  ) : (
                    <p className="step-question">
                      Seu perfil (conta) no Instagram é comercial?
                    </p>
                  )}
                </div>
              )}

              {/* step 2 */}
              {stepActive === 2 && (
                <div
                  className={`step-content step-2 ${
                    arrayIndex[arrayIndex.length - 2] > stepActive
                      ? 'reverter'
                      : ''
                  }`}
                >
                  {!businessAccount ? (
                    <>
                      <span className="step-text" />

                      <button
                        type="button"
                        onClick={() => openVideo()}
                        className="button-animation button-play"
                      >
                        <div className="icon-play" />
                        Veja como fazer
                      </button>
                    </>
                  ) : (
                    <p className="step-question">
                      Você possui conta no Facebook?
                    </p>
                  )}
                </div>
              )}

              {/* step 3 */}
              {stepActive === 3 && (
                <div
                  className={`step-content step-3 ${
                    arrayIndex[arrayIndex.length - 2] > stepActive
                      ? 'reverter'
                      : ''
                  }`}
                >
                  {!businessAccount ? (
                    <>
                      <span className="step-text">
                        Veja o vídeo e siga os passos para poder transformar o
                        seu perfil em uma conta comercial no Instagram.
                      </span>

                      <button
                        type="button"
                        onClick={() => openVideo()}
                        className="button-animation button-play"
                      >
                        <div className="icon-play" />
                        Veja como fazer
                      </button>
                    </>
                  ) : (
                    <p className="step-question">
                      Você possui uma página do Facebook e sua página está
                      conectada com a sua conta do Instagram?
                    </p>
                  )}
                </div>
              )}
            </div>
          ) : (
            <div className="complet-tutorial">
              <p className="text-complet">
                Sua conta segue os critérios solicitados para fazer o conecte.
              </p>

              <div className="content-connect" id="connect">
                <div
                  className="btn-social fb hover"
                  role="button"
                  onClick={() => {
                    const fbButton = document.querySelector(".kep-login-facebook")

                    if(fbButton) fbButton.click()
                  }}
                >
                  <div className="icon-social">
                    <img src={instagramImg} alt="logo do instagram" />
                  </div>

                  <FacebookLogin
                    appId="1009050182802521"
                    onClick={(e) => {
                      callback(false)

                      e.stopPropagation()
                    }}
                    textButton="Conecte o Instagram pelo Facebook"
                    callback={facebookCallback}
                    scope="business_management, pages_read_engagement, instagram_manage_insights, instagram_basic, pages_show_list"
                  >
                  </FacebookLogin>
                </div>
              </div>
            </div>
          )}

          {businessAccount && !completTutorial && (
            <div className="step-buttons">
              <button
                type="button"
                onClick={() => changeStep('n')}
                className="button-animation button-cancel"
              >
                <i className="icon icon-close" />
                Não
              </button>

              <button
                type="button"
                onClick={() => changeStep('y')}
                className="button-animation button-ok"
              >
                <i className="icon icon-check" />
                Sim
              </button>
            </div>
          )}
        </div>
      </div>
    )
  )
}

TutorialConnectIG.defaultProps = {
  show: false,
  callback: () => true,
  updateStatusConnect: () => {},
  hiddenTutorial: () => {},
}

TutorialConnectIG.propTypes = {
  show: PropTypes.bool,
  callback: PropTypes.func,
  updateStatusConnect: PropTypes.func,
  hiddenTutorial: PropTypes.func,
}

export default TutorialConnectIG
