import React from "react"
// 
import { useNavigate } from "react-router-dom"
// 
import Button from "../Button"
// 
import logo from "../../assets/images/logo.svg"
// 
import "./index.scss"

const Header = () => {
    const navigate = useNavigate()
    
    return <header>
        <div className="box-header">
            <img 
                src={logo} 
                alt="airfluencers logo" 
                onClick={() => navigate("")}
            />

            <Button
                className="outline-primary"
                onClick={() => window.open("https://airfluencers.com/")}
            >
                Visitar o site

                <i className="icon icon-arrow-next" />
            </Button>
        </div>
    </header>
}

export default Header
