import {
    INITIAL_STATE
} from "."
// 
import PropTypes from "prop-types"
// 
import AppContextActions from "../utils/actions"
import StateStatus from "../utils/status"
// 

const appContextReducer = (state = INITIAL_STATE, action) => {
    const {
        type
    } = action
    // 
    const tmpState = {
        ...state
    }

    switch (type) {
        case AppContextActions.UpdateStatus:
            const {
                name, status
            } = action

            return {
                ...tmpState,
                [name]: status
            }

        case AppContextActions.getProfilesToCompare:
            try {
                const {
                    profiles
                } = action

                return {
                    profiles: profiles
                }
            } catch {
                tmpState.getInfluencerToCompareStatus = StateStatus.failed
            }

            break;

        default:
            throw new Error("Type is not defined")
    }

    return {
        ...tmpState
    }
}

appContextReducer.propTypes = {
    state: PropTypes.object,
    action: PropTypes.object,
}

export default appContextReducer