import moment from "moment"

export const humanize = (val) => {
    const ret = String(parseInt(val, 10) || 0)

    switch (ret.length) {
        case 4:
            return ret.slice(0, 1) + ((parseInt(ret.slice(1, 2), 10) === 0) ? "" : "." + ret.slice(1, 2)) + "k"
        case 5:
            return ret.slice(0, 2) + "k"
        case 6:
            return ret.slice(0, 3) + "k"
        case 7:
            return ret.slice(0, 1) + ((parseInt(ret.slice(1, 2), 10) === 0) ? "" : "." + ret.slice(1, 2)) + "M"
        case 8:
            return ret.slice(0, 2) + "M"
        case 9:
            return ret.slice(0, 3) + "M"
        case 10:
            return ret.slice(0, 1) + ((parseInt(ret.slice(1, 2), 10) === 0) ? "" : "." + ret.slice(1, 2)) + "B"
        case 11:
            return ret.slice(0, 2) + "B"
        case 12:
            return ret.slice(0, 3) + "B"
        default:
            return ""
    }
}

export const buildValue = (name, value) => {
    if (name === "date") {
        const lang = localStorage.getItem("lang") || "pt"

        return moment(value).format(lang === "en" ? "MM/DD/yyyy" : "DD/MM/yyyy")
    } else if (name === "date2") {
        return moment(value).format("DD MMM. YY")
    } else if (name === "dateString") {
        const lang = localStorage.getItem("lang") || "pt"
        
        return moment(value).format(lang === "en" ? "MM/DD" : "DD/MM")
    } else if (name === "number") {
        return `${value || 0}`.replace(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    } else if (name === "percent") {
        return `${parseFloat(`${value}`)}%`.replace(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    } else if (name === "humanize") {
        return humanize(value)
    } else if (name === "currency") {
        return parseFloat(value).toLocaleString("pt-RF", {
            style: "currency",
            currency: "BRL",
        })
    }

    return value
}

export const hexToRgba = (hex, alpha = 1) => {
    var c

    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split("")

        if (c.length === 3) c = [c[0], c[0], c[1], c[1], c[2], c[2]]

        c = "0x" + c.join("")

        return `rgba(${[(c>>16)&255, (c>>8)&255, c&255].join(",")},${alpha})`
    }

    return "#FFF"
}
