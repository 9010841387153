import React from "react"
import { useNavigate } from "react-router-dom"
// 
import Button from "../../components/Button"
// 
import errorImg from "../../assets/images/404.svg"
// 
import "./index.scss"

const Error = () => {
    const query = new URLSearchParams(window.location.href.split("?")[1])
    const type = query.get("type")
    // 
    const navigate = useNavigate()

    return <div className="page error-page">
        <h1>Ops!</h1>
        
        <img src={errorImg} alt="Desenho de um homem branco de cabelo preto apoiando o rosto nas mãos com três interrogações acima de sua cabeça" />

        {type === "1" && <>
            <p>A sua conta adicionada não está no modelo comercial</p>

            <p>Apenas <a href="https://airfluencers.com/" target="_blank" rel="noopener noreferrer">contas comerciais</a> podem ser analisadas tente novamente</p>

        </>}
        
        {type === "2" && <>
            <p>É necessário fazer o conecte para liberar a comparação dos perfis</p>
        </>}

        {type === "3" && <>
            <p>Token inválido</p>
        </>}
        
        <Button
            onClick={() => navigate("/connect")}
        >
            Tentar novamente
        </Button>
    </div>
}

export default Error