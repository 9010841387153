import "./index.scss"

class FeedbackPattern {
    _timeOutRemoveClass
    _timeOutRemoveElement
    //
    _icons = {
        "success": "icon-circle-check",
        "error": "icon-close",
        "warning": "icon-alert",
        "info": "icon-info",
    }

    _createElement(text, type) {
        const card = document.createElement("div")
        card.classList.add("feedback-alert", type)
        // 
        const i = document.createElement("i")
        i.classList.add("icon", this._icons[type])
        // 
        const close = document.createElement("i")
        close.classList.add("icon", "icon-close")
        close.addEventListener("click", () => this._removeAlert(600))
        // 
        const divText = document.createElement("div")
        divText.classList.add("container-text")
        // 
        const txt = document.createElement("span")
        txt.classList.add("feedback-alert-txt")
        txt.textContent = text
        // 
        divText.appendChild(txt)
        card.appendChild(i)
        card.appendChild(divText)
        card.appendChild(close)

        return card

        // <div className={`feedback-alert ${type}`}>
        //     {this._icons[type]}

        //     <div className="container-text">
        //         <span className={`feedback-alert-text ${type}`}>{text}</span>
        //     </div>

        //      <i className="icon icon-close" />
        // </div>
    }

    _removeAlert(time = 5000) {
        const body = document.body
        
        this._timeOutRemoveClass = setTimeout(() => {
            const element = document.querySelector(".feedback-alert")

            if(element) element.classList.add("removed")
        }, (time - 300));

        this._timeOutRemoveElement = setTimeout(() => {
            const element = document.querySelector(".feedback-alert")

            if(element) body.removeChild(element)
        }, time);
    }

    _alert(text, type) {
        if(this._timeOutRemoveClass) clearTimeout(this._timeOutRemoveClass)
        if(this._timeOutRemoveElement) clearTimeout(this._timeOutRemoveElement)

        if (!text || !type) 
            throw Object.assign(
                new Error("Error"),
                { code: 402 }
            )
        
        const body = document.body
        const div = document.querySelector(".feedback-alert")

        if(div) body.removeChild(div)
        
        const alert = this._createElement(text, type)

        body.appendChild(alert)

        this._removeAlert()
    }
}

class FeedbackTypes extends FeedbackPattern {
    info(text) {
        super._alert(text, "info")
    }

    success(text) {
        super._alert(text, "success")
    }

    error(text) {
        super._alert(text, "error")
    }

    warning(text) {
        super._alert(text, "warning")
    }
}

const feedback = new FeedbackTypes()

export default feedback
