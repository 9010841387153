import React, { useRef } from "react"
import PropTypes from "prop-types"
// 
import "./index.scss"

const Input = ({
    label,
    placeholder,
    value,
    type,
    className,
    onChange,
    msgError,
    isOutlined,
}) => {
    const inputRef = useRef(null)

    return <div className="input-junction">
        {label && 
            <label>{label}</label>
        }
        
        <input
            ref={inputRef}
            value={value}
            type={type}
            onChange={onChange}
            placeholder={placeholder}
            className={`input ${className} ${msgError ? "input-error" : ""} input-${isOutlined ? "outline" : "full"}`} 
        />

        {msgError && 
            <span className="error">{msgError}</span>
        }
    </div>
}

Input.propTypes = {
    isOutlined: false,
    className: "",
    type: "text",
}

Input.propTypes = {
    value: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    type: PropTypes.string,
    msgError: PropTypes.string,
    className: PropTypes.string,
    isOutlined: PropTypes.bool,
}

export default Input
  