import React from "react"
import PropTypes from "prop-types"

const ListOfCategoryMetrics = ({
    categories,
    worstCategories
}) => {
    return <div className="list-of-metrics list-of-category-metrics">
        {categories.map((category, index) => {
            return <div className={`list-item ${index === 0 ? "first" : ""}`} key={index}>
                <div className="metric">
                    <span className="number">#{index + 1}</span>
                    
                    {category.icon &&
                        <i 
                            className={`icon icon-${category.icon}`} 
                            style={{ 
                                color: `#${category.color}`,
                                borderColor: `#${category.color}` 
                            }}    
                        />
                    }

                    <span className="name">{category.name || category.label}</span>
                </div>

                <span className="value">{category.value}</span>
            </div>
        })}
        

        {worstCategories && worstCategories.length > 2 && <>
            <p className="title-worst-categories">
                <i className="icon icon-arrow-top" />
                Airscores mais baixos
                <i className="icon icon-arrow-top" />
            </p>

            {worstCategories.map((category, index) => {
                return <div className="list-item list-item-worse" key={index}>
                    <div className="metric">
                        <span className="number">#{category.index + 1}</span>

                        {category.icon &&
                            <i 
                                className={`icon icon-${category.icon}`} 
                                style={{ 
                                    color: `#${category.color}`,
                                    borderColor: `#${category.color}` 
                                }}    
                            />
                        }
                        <span className="name">{category.name || category.label}</span>
                    </div>

                    <span className="value">{category.value}</span>
                </div>
            })}
        </>}
    </div>
}

ListOfCategoryMetrics.propTypes = {
    categories: PropTypes.array,
    worstCategories: PropTypes.array,
}

export default ListOfCategoryMetrics