import React from "react"
// 
import AppContextProvider from "../context/provider"
// 
import Header from "../components/Header"
// 
import Routes from "../pages/routes"
// 
import "../assets/styles/icons.scss"
import "./index.scss"

const App = () => {
  return <div className="pattern-page">
    <AppContextProvider>
      <Header />

      <div className="content-page">
        <Routes />
      </div>
    </AppContextProvider>
  </div>
}

export default App
