import React from "react"
// 
import Button from "../../components/Button"
// 
import Clients from "./components/Clients";
import ConnectBox from "./components/ConnectBox";
// 
import women from "../../assets/images/women.png"
// 
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./index.scss"

const Connect = () => {
    return <div className="page connect-page">
        <div className="about-comparator">
            <div className="texts">
                <h1>Compare seu sucesso no Instagram</h1>

                <h2>Tenha insights e compare seu desempenho com de outros influenciadores.</h2>

                <Button
                    onClick={() => {
                        window.scrollTo({
                            top: window.outerHeight,
                            behavior: "smooth"
                        })
                    }}
                >
                    Quero comparar
                </Button>
            </div>

            <img src={women} alt="mulher mexendo no celular e analisando o crescimento de sua base de seguidores junto ao seu engajamento" />
        </div>
        
        <Clients />

        <div className="exclusive-access">
            <h3>Quero acesso exclusivo</h3>
            
            <p>Conecte-se e compare dados do Instagram.</p>

            <div className="benefits">
                <p>
                    <span className="check" />
                    Perfis conectados ganham destaque para as agências!
                </p>
                <p>
                    <span className="check" />
                    Ganhe acesso exclusivo aos dados utilizados pelas agências.
                </p>
                <p>
                    <span className="check" />
                    Compare o seu perfil com outros do seu nicho.
                </p>
            </div>
        </div>

        <ConnectBox />

        <span className="privacy-policy">
            Verifique os <a href="https://airfluencers.com/politica-de-privacidade/">termos de uso</a> e a <a href="https://airfluencers.com/politica-de-privacidade/">política de privacidade</a> da Airfluencers.
        </span>
    </div>
}

export default Connect