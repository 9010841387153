const AppContextActions = {
    // AUX
    UpdateStatus: "update-status",
    // REQUESTS
    SendForm: "send-form",
    UserAuth: "user-auth",
    SendFormEmail: "send-form-email",
    getProfilesToCompare: "get-influencer-to-compare",
}

export default AppContextActions
