import React, { useState } from "react"
import PropTypes from "prop-types"
// 
import medalImg from "../../../../assets/images/medal.svg"
// 
import "./index.scss"

const TopPost = ({
    post
}) => {
    const [showAllDescription, updateShowAllDescription] = useState(false)

    return <div className="top-post">
        <img className="medal" src={medalImg} alt="medalha de primeiro lugar na cor rosa" />

        <div className="engagement">
            Engajamento

            <span>{post.engagementRate}%</span>
        </div>

        <div    
            className="post-image" 
            style={{ backgroundImage: `url(${post.thumbnail})`}} 
        >
            <i className="icon icon-extenal-link"/>
        </div>
        
        <span className="description">
            {post.description !== post.descriptionSlice ? 
                <> 
                    {showAllDescription ? post.description : post.descriptionSlice}
                    
                    <b 
                        role="button"
                        onClick={() => updateShowAllDescription(!showAllDescription)}
                    >
                        {showAllDescription ? " Mostrar menos" : "Mostrar mais..."}
                    </b>
                </>
                :    
                post.description
            }
        </span>
    </div>
}

TopPost.propTypes = {
    post: PropTypes.object,
}

export default TopPost