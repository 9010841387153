import React from "react"
import PropTypes from "prop-types"
// 
import Card from "../../../../components/Card"
import Avatar from "../../../../components/Avatar"
// 
import "./index.scss"

const InfluencerCardData = ({
    influencer,
    showNetwork,
    children
}) => {
    return <Card className="card-influencer">
        <Avatar 
            network={showNetwork && "instagram"}
            src={influencer.avatar}
        />

        <span className="name">{influencer.name}</span>

        {children}
    </Card>
}

InfluencerCardData.defaultProps = {
    showNetwork: false,
}

InfluencerCardData.propTypes = {
    influencer: PropTypes.object,
    children: PropTypes.element,
    showNetwork: PropTypes.bool,
}

export default InfluencerCardData